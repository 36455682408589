<template>
  <div class="aiz-user-panel">
    
    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h1 class="h3">{{ $t('guan-li-ge-ren-zi-liao') }}</h1>
        </div>
      </div>
    </div>

    <br>
    <form>
      <div class="card">
        <div class="card-header">
          <h5 class="mb-0 h6">{{ $t('jiao-yi-mi-ma-0') }}</h5>
        </div>
        <div class="card-body">
          <div class="form-group row" v-if="isSetPassword">
            <label class="col-md-2 col-form-label" for="spwd">{{ $t('yuan-mi-ma') }}</label>
            <div class="col-md-10">
              <input type="password" v-model="form.oldPassword" class="form-control"  :placeholder="$t('yuan-mi-ma-0')" required>
            </div>
          </div>             
          <div class="form-group row">
            <label class="col-md-2 col-form-label" for="password">{{isSetPassword ? $t('xin-mi-ma') : $t('jiao-yi-mi-ma')}}</label>
            <div class="col-md-10">
              <input type="password" v-model="form.password" class="form-control"  :placeholder="isSetPassword ? $t('xin-mi-ma') : $t('jiao-yi-mi-ma')" required>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-form-label" for="confirm_password">{{ $t('que-ren-mi-ma-0') }}</label>
            <div class="col-md-10">
              <input type="password" v-model="form.password2" class="form-control" :placeholder="$t('que-ren-mi-ma-0')" >
            </div>
          </div>
          <div class="form-group text-right">
            <button type="button" class="btn btn-sm btn-primary" @click="submit">{{ $t('bao-cun') }}</button>
          </div>
        </div>
      </div>
    </form>
    
  </div>
</template>
<script>
import pageBar from '@/components/page'
import {Popup} from 'vant'
import { checkPayPassword, setPayPassword, editPayPassword } from '@/api/user'
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      isSetPassword: false,
      form: {
        oldPassword: '',
        password: '',
        password2: ''
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      checkPayPassword().then(res => {
        this.isSetPassword = res.data.IsSet
      })
    },
    submit() {
      if (this.isSetPassword && !this.form.oldPassword) {
        this.$toast.fail(this.$t('qing-shu-ru-yuan-mi-ma'))
        return
      }
      if (!this.form.password) {
       this.$toast.fail(`${this.$t('qing-shu-ru')}${this.isSetPassword ? this.$t('xin-mi-ma') : this.$t('jiao-yi-mi-ma')}`)
        return
      }
      if (!this.form.password2) {
         this.$toast.fail(this.$t('qing-que-ren-mi-ma'))
        return
      }
      if (this.form.password != this.form.password2) {
         this.$toast.fail(this.$t('liang-ci-shu-ru-de-mi-ma-bu-yi-zhi'))
        return
      }
      let form = new FormData()
      if (this.isSetPassword) {
        form.append('OldPassword', this.form.oldPassword)
        form.append('Password', this.form.password)
        form.append('PasswordConfirm', this.form.password2)
        editPayPassword(form).then(res => {
          if (res.code == 0) {
            this.$toast(this.$t('xiu-gai-cheng-gong'))
            this.form.oldPassword = ''
            this.form.password = ''
            this.form.password2 = ''
          } else {
            this.$toast.fail(res.msg)
          }
        })
      } else {
        form.append('Password', this.form.password)
        setPayPassword(form).then(res => {
          if (res.code == 0) {
            this.isSetPassword = true
            this.$toast(this.$t('she-zhi-cheng-gong'))
            this.form.password = ''
            this.form.password2 = ''
          } else {
            this.$toast.fail(res.msg)
          }
        })
      }
    }
  }
}
</script>